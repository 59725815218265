import React, { useContext } from "react"
import Layout from "../components/layout"
import { Stack, Switch } from "@chakra-ui/core"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import { GlobalState } from "../types/types"

const SettingsPage = () => {

  const state = useContext(GlobalStateContext) as GlobalState
  const dispatch = useContext(GlobalDispatchContext)


  const handlePersonalProjects = () => {

    state.personalProjects?dispatch({ type: "REMOVE_PERSONAL_PROJECTS" }):dispatch({ type: "ADD_PERSONAL_PROJECTS" });
    
  }

  const handleAnimationFromMainPage = () => {
    if (state.mainPageAnimations) {
      dispatch({ type: "REMOVE_MAIN_PAGE_ANIMATIONS" });
    }
    else {
      dispatch({ type: "ADD_MAIN_PAGE_ANIMATIONS" });

    }
  }

  const handleMenuFixedForDesktop = () => {
    state.menuFixedForDesktop ?dispatch({ type: "MENU_RELATIVE_DESKTOP" }) : dispatch({ type: "MENU_FIXED_DESKTOP" });
  }

  return (
    <Layout>
      <div className="container settings-page-container">
        <h1>App Settings</h1>
        <br></br>
        <h2>Global</h2>

        <Stack align="center" direction="row">
          <p className="no-margin">Fixed navigation for Desktop</p>
          <Switch className={state.menuFixedForDesktop ? 'active-switch' : ''} isChecked={state.menuFixedForDesktop ? true : false} marginLeft="15px" onChange={handleMenuFixedForDesktop} size="lg" />
        </Stack>
        <br></br> 
        <h2>Main page</h2>

        <Stack align="center" direction="row">
          <p className="no-margin">Desktop Intro Animations</p>
          <Switch className={state.mainPageAnimations ? 'active-switch' : ''} isChecked={state.mainPageAnimations ? true : false} marginLeft="15px" onChange={handleAnimationFromMainPage} size="lg" />
        </Stack>

        <br></br>

        <h2>My work</h2>

        <Stack align="center" direction="row">
          <p className="no-margin">Include personal Projects</p>
          <Switch isDisabled={state.projectsList?false:true} className={state.personalProjects ? 'active-switch' : ''} isChecked={state.personalProjects ? true : false} marginLeft="15px" onChange={handlePersonalProjects} size="lg" />
        </Stack>

      </div>

    </Layout>
  )
}

export default SettingsPage
